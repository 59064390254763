@import './../sass/reuse';

.playlist {
  position: relative;
  margin: 0.75rem;
  overflow: hidden;
  border-radius: 4px 4px 15px 15px;

  &Container {
    display: flex;

    @include mobile {
      display: block;
    }
  }

  @include mobile {
    height: 200px;
    margin-bottom: 25px;
  }

  img {
    display: block;
    height: 228px !important;
    width: 100%;
    object-fit: cover;
    opacity: 0.79 !important;
    position: relative;
    transition: transform $duration * 2.5 $function;

    @include mobile {
      height: 200px !important;
      border-radius: 10px;
      background-image: linear-gradient(
        -2deg,
        #000000 0%,
        rgba(0, 0, 0, 0) 100%
      );
      opacity: 0.79;
    }

    &:hover {
      transform: scale(1.25);
    }
  }
  .playlistName {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    font-size: 29px;
    color: #fff;
    letter-spacing: 2px;
    text-transform: uppercase;
    z-index: 1;
    transition: transform $duration * 2.5 $function;

    &:hover {
      + img {
        transform: scale(1.25);
      }
    }
  }
}
