@import './../sass/reuse';

.resource {
  flex-direction: column;
  h3 {
    margin-bottom: 40px;

    @include mobile {
      text-align: center;
      margin-bottom: 20px;
      margin-top: 8px;
      font-size: 12px;
    }
  }

  .blogs,
  .podcast,
  .videos {
    box-shadow: 0 23px 57px rgba(0, 0, 0, 0.16);
  }
}

.resourceWrapper {
  background-color: var(--resource-background-color);
  border-radius: $block-radius;
  padding: $gap / 2 0 $gap / 2 $gap / 2;
  flex: 1;
  .podcastSubtitle {
    font-weight: bold;
  }
  &:hover {
    .podcastTitle {
      color: $purple;
    }
  }

  @include desktop {
    padding: $gap $gap $gap / 2 $gap;
    display: flex;
    align-items: center;
    min-height: 250px;
  }

  @include mobile {
    font-size: 14px;
    padding: 30px 25px;
  }
}

.podcast {
  img {
    margin-bottom: $gap / 2;

    @include mobile {
      margin-bottom: 0;
    }
    @include desktop {
      min-width: 155px !important;
      min-height: 155px !important;
    }
  }

  h4,
  p {
    color: var(--resource-color);
    text-transform: none;
    margin-bottom: $gap / 2;
  }

  h4 {
    font-size: 18px;
    font-weight: $weight-bold;

    @include mobile {
      font-size: 14px;
    }
  }
}

.blogs {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  nav {
    background-color: var(--resource-background-color);
    padding: 10px;
    :global(.swiper-slide) {
      width: min-content !important;
    }
    :global(.swiper-wrapper) {
      width: 0;
    }
    :global(.swiper-wrapper) {
      width: 0;
    }
    :global(.swiper-button-prev) {
      left: -20px;
      &:before {
        transform: scale(-0.7);
      }
    }
    :global(.swiper-button-next) {
      right: -20px;
      &:before {
        transform: scale(0.7);
      }
    }
    a {
      color: var(--nav-link-color);
      padding: 4px;
      font-weight: $weight-bold;
      font-size: 11px;
      letter-spacing: 1px;
      display: block;
      text-transform: uppercase;
      position: relative;

      @include fullhd {
        font-size: 12px;
        letter-spacing: 2.5px;
        padding: 6px;
      }

      &:after {
        will-change: width;
        transition: all $speed * 2;
        content: '.';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 0%;
        color: transparent;
        background-color: $link-hover;
        height: 1px;
      }

      &:hover {
        &:after {
          width: 100%;
          background-color: var(--nav-link-color);
        }
      }
    }
  }
  .blogPosts {
    flex: 1;
    display: flex;
  }
}

.imageContainer {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  .isPostActive {
    blockquote {
      border-left-color: $cyan;
    }
  }
  img {
    opacity: 0.6;
    filter: blur(1.5px);
  }
}

.post {
  flex: 1;
  padding: 10px 30px 10px 40px;
  z-index: 1;
  @include mobile {
    max-width: unset !important;
  }
  @include touch {
    padding-left: 20px;
  }
  blockquote {
    color: $white;
    padding-left: $gap / 2;
    border-left: 2px solid transparent;
    font-size: 18px;
    font-weight: $weight-bold;
    margin-bottom: $gap / 4;

    @include touch {
      font-size: 14px;
    }
  }
  &:first-of-type {
    padding-top: 15px;
    padding-bottom: 0;
  }

  .postInfo {
    padding-top: 7px;
    color: $cyan;
    font-size: 11px;
    font-weight: $weight-normal;
    letter-spacing: 0.55px;
    text-transform: uppercase;
  }
}

.videos {
  a {
    position: relative;
    &:hover {
      img {
        filter: brightness(10%);
      }
      svg {
        visibility: visible;
      }
    }
  }
  img {
    @include worship-transition(filter);
  }
  svg {
    visibility: hidden;
    font-size: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 1;
    @include worship-transition(color);
  }
  h4 {
    text-transform: none;
    font-size: 19px;
    font-weight: $weight-normal;
    margin-top: 12px;
    color: var(--resource-color);
    text-align: center;

    @include mobile {
      font-size: 14px;
    }
  }
  span:first-of-type {
    left: 50%;
    transform: translateX(-50%);
  }
}

.podcastImage {
  border-radius: 10px;
}

.concatenated {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
