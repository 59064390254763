@import './../sass/reuse';

$container-height-desktop: 305px;

.storeContainer {
  color: $body-background-color;
  border-top-left-radius: $block-radius;
  border-top-right-radius: $block-radius;
  overflow: hidden;

  @include desktop {
    display: flex;
    align-items: stretch;
    min-height: 305px;
  }

  &:last-of-type {
    border-bottom-left-radius: $block-radius;
    border-bottom-right-radius: $block-radius;
    color: $white;

    .storeHeader {
      background-color: var(--store-last-header-background-color);

      a:hover {
        h3,
        strong {
          color: $purple;
        }
      }
    }

    .storeWrapper {
      background-color: var(--store-last-wrapper-background-color);
      h3,
      h4 {
        color: var(--store-last-wrapper-color);
      }
    }

    .storeArtist {
      &:hover {
        img {
          border-color: #131313;
        }
      }
    }

    h3,
    h4,
    h5,
    strong {
      color: var(--store-last-header-color);
    }
  }
}

.storeHeader {
  font-family: $family-sans-serif-light;
  background-color: var(--store-header-background-color);
  padding: 1em 0;
  text-align: center;

  @include desktop {
    min-width: 509px;
    padding: 0 75px;
    display: flex;
    align-items: center;
    text-align: left;
  }

  a {
    color: var(--store-header-color);
    &:hover {
      color: $purple;
    }
  }

  h3 {
    font-size: $size-6;
    letter-spacing: 4.6px;
    font-weight: $weight-light;
    @include tablet {
      font-size: 23px;
    }
  }
}

.storeWrapper {
  background-color: var(--store-wrapper-background-color);
  flex-grow: 1;
  padding: 1em;
  overflow: hidden;
  figure {
    span:first-child {
      display: contents !important;
    }
  }

  @include mobile {
    & > div {
      overflow: auto;
    }
  }
}

.storeArtist {
  display: flex;
  justify-content: center;
  flex-basis: 25%;

  @include desktop {
    min-height: calc(305px - 1em);
  }

  &:hover {
    img {
      border-color: #fff;
      opacity: 0.65;
    }
  }

  img {
    border: 2px solid transparent;
    object-fit: cover;
    transition: all $duration $function;
    transition-property: border, opacity;
  }

  h4,
  h5 {
    text-transform: none;
    color: var(--store-wrapper-color);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  h4 {
    font-size: 22px;
    @include mobile {
      font-size: 14px;
    }
  }

  h5 {
    font-size: 15px;
    @include mobile {
      font-size: 11px;
    }
  }

  figure {
    width: 100%;
    text-align: center;
    padding: $gap / 2;
  }

  figcaption {
    margin-top: $gap / 2;
    overflow: hidden;
  }
}
