@import './../sass/reuse';

$artist-item-border-radius: 10px;

.artistItem {
  img {
    object-fit: cover;
    border-radius: $artist-item-border-radius;

    @include mobile {
      border-radius: 0;
    }
  }

  &:not(.fullMode) {
    img {
      transition: transform $duration $function;
      transform: scale(1) translateY(0);
      will-change: transform, box-shadow;
      &:hover {
        transform: scale(1.1) translateY(-8px);
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.37);
      }
    }
  }

  .artistSquareImage {
    background-color: #1f1f1f;
    div {
      overflow: visible !important;
    }
    & > span {
      display: contents !important;
    }
  }

  &.fullMode {
    img {
      transition: opacity 200ms;
    }
    .artistName {
      font-size: 31px;
      color: #fff;
      margin-top: 0;
      line-height: 36px;
      position: absolute;
      bottom: 15px;
      left: 20px;
      right: 20px;
      transition: all 200ms;

      @include mobile {
        font-size: 18px;
        line-height: normal;
      }
    }

    .artistHolder {
      border-radius: $artist-item-border-radius;
      overflow: hidden;
      height: 349px;

      & > div:first-child {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        width: 100%;
        height: 100%;

        & > * {
          width: 100%;
          height: 100%;
        }
      }

      @include mobile {
        height: 155.5px;
      }
    }

    &:hover {
      img {
        opacity: 0.22;
      }
      .artistName {
        transform: translateY(200%);
      }
      .artistInfo {
        transform: translateY(0%);
      }
    }
  }

  &:hover {
    a {
      color: #fff;
    }
  }
}

.artistName {
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
  position: relative;

  @include mobile {
    font-size: 10px;
  }
}

.artistInfo {
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translateY(-80%);
  transition: all 200ms;

  .artistInfoRow {
    display: flex;
    width: 100%;
    span,
    p {
      color: #fff;
      font-size: 31px;
      line-height: 36px;
      text-shadow: 0px 0px 5px rgb(0 0 0 / 0.5);

      @include mobile {
        font-size: 24px;
        line-height: 32px;
      }
    }
    span {
      text-align: right;
      display: inline-block;
      width: calc(50% - 32px);
      margin-right: 15px;
      border-right: 2px solid #e728ea;
      padding-right: 15px;
    }
    p {
      text-align: left;
    }
  }
}
