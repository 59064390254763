@import '../../sass/reuse';

.listSort {
  width: 290px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: $gap / 2;
  .listSortButton {
    margin-right: 28px;
    display: inline-block;
  }
  .filterActiveType {
    position: relative;
    font-weight: $weight-bold;
    &:after {
      content: '|';
      margin: 0 10px;
    }
  }
  .filterActiveValue {
    font-weight: 100;
  }

  @include mobile {
    justify-content: center;
  }
}

.listSortContent {
  border-radius: 34px;
  font-size: 0.7rem;
  letter-spacing: 2.75px;
  text-transform: uppercase;
  border: 1px solid transparent;
  padding: 10px 25px;
  text-align: left;
  white-space: nowrap;
  background-color: var(--header-background-color);
  border-color: var(--button-black-border-color);
  color: var(--sort-button-color);

  @include mobile {
    padding: 10px 15px;
    font-size: 10px;
    margin-left: 0;
    margin-right: 0;
    width: auto;
  }
}
.listSortContentActive {
  background: #fff;
  color: #000;
  padding-right: 25px;
  position: relative;
  border-radius: 17px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  width: 290px;
  overflow: inherit;
  &:hover {
    background: #fff;
    color: #000;
  }
  .filterValuesList {
    display: block;
    border: 1px solid var(--button-black-border-color);
    border-top: none;
  }

  @include mobile {
    margin-left: 0;
    margin-right: 0;
    width: auto;
  }
}
.listSortCategory {
  display: inline-block;
  width: 100%;
  background: rgba(23, 7, 7, 0.06);
  box-sizing: border-box;
  padding: 10px 25px;
  font-size: 0.47rem;
  font-weight: $weight-bold;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.filterValuesList {
  display: none;
  margin: 0;
  padding: 15px 0;
  position: absolute;
  z-index: 999;
  background: #fff;
  left: -1px;
  right: -1px;
  top: 25px;
  text-align: initial;
  border-bottom-right-radius: 17px;
  border-bottom-left-radius: 17px;
  li:hover {
    color: $purple;
  }
}

.filterValuesListItem {
  cursor: pointer;
  box-sizing: border-box;
  display: block;
  padding: 7px 20px;
  font-weight: $weight-bold;
  text-transform: uppercase;
  width: 100%;
  text-align: left;

  &:hover {
    background-color: $grey-light;
    color: $purple;
  }
  &.selected {
    color: $purple;
  }
}

.storeListSort {
  width: 260px;
  font-size: 25px;
  letter-spacing: initial;
  background: none;
  padding: 0 15px;
  border: none;
  color: #3e3e3e;
  text-transform: capitalize;
  &:hover {
    background: none;
    color: initial;
  }
  svg {
    padding-left: 10px;
  }
  &.listSortContentActive {
    background: #fff;
  }
  & > div:nth-child(2) {
    top: 32px;
    right: 0;
    left: 0;
    border: none;
    box-shadow: 10px 15px 15px rgb(0 0 0 / 10%);
  }

  @include mobile {
    font-size: 18px;
    padding: 0;
  }
}
